import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
    placeArc,
    placeLogo,
    placeErase,
    placeShuffle,
    placeTest,
    placeSeg,
    setConfig,
    placeStat,
    placeLeft,
    placeRight
} from '../../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
  JXG.Options.board.minimizeReflow = 'none';
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-1, 20, 20, -1], keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
brd1.options.point.showInfobox =false;
brd1.options.point.highlight =false;
brd1.suspendUpdate();
//Making the board responsive
makeResponsive(brd1);
//placing a Logo
placeLogo(brd1);
      //
var title = brd1.create('text', [9.5, 18, '<b> Complementary Angles<b>'],{fixed:true, anchorX:'middle', anchorY:'middle',CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)}},);
var subtitle = brd1.create('text', [9.5, 16, ' The sum of such two angles is 90° '],{fixed:true, anchorX:'middle', anchorY:'middle',CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);

var title2 = brd1.create('text', [9.5, 18, '<b> Supplementary Angles <b>'],{fixed:true, anchorX:'middle', anchorY:'middle', visible: false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)}},);
var subtitle2 = brd1.create('text', [9.5, 16, ' The sum of such two angles is 180° '],{fixed:true, anchorX:'middle', anchorY:'middle',visible: false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}, fixed:true},);

var subtitle3 = brd1.create('text', [0, 18, ' Can you find the pairs of complementary and supplementary angles? '],{visible: false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)},fixed:true});

var text1 = brd1.create('text', [2, 3.5, ' Complementary '],{fixed:true, visible: false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
var text2 = brd1.create('text', [13, 3.5, ' Supplementary '],{fixed:true, visible: false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);

//var score1 = 0;
//var score2 = 0;

//var scoretxt2 = brd1.create('text', [18, 16.5,  function (){return  score1 + score2}],{visible: false, color: 'black', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)}},);

//COMPLEMENTARY

//Variables complementary
var Pt1_90_comp = brd1.create('point', [6,5],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var Pt2_90_comp = brd1.create('point', [14, 5],{visible: false, name:' ', size:2, strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var Pt3_90_comp = brd1.create('point', [6, 13],{visible: false, name:' ', size:2,  strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});


var Pt1_60_comp = brd1.create('point', [8,7],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var Pt3_60_comp = brd1.create('point', [8, 13],{visible: false, name:' ', size:2,  strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var Pt2_30_comp_fixed = brd1.create('point', [14, 7],{visible: false, name:' ', size:2, strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var circle_comp = brd1.create('arc', [Pt1_60_comp, Pt2_30_comp_fixed, Pt3_60_comp], {dash:1, strokeWidth:1, strokeColor:'grey', visible:true});
var Pt2_60_comp = brd1.create('glider', [13, 10 , circle_comp ],{visible: true, name:'Drag Me!', size:2, strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var Pt1_30_comp = brd1.create('point', [function(){return Pt1_60_comp.X()},function(){return Pt1_60_comp.Y()}],{visible: false, name:'A', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:true, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var Pt2_30_comp = brd1.create('point', [14, 7],{visible: false, name:'B', size:2, strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

//Sides of the triangle complementary
var AB_90_comp = brd1.create('segment', [Pt1_90_comp, Pt2_90_comp], {strokeColor:'black', dash: 1 , strokeWidth:2, fixed:true});
var AC_90_comp = brd1.create('segment', [Pt1_90_comp, Pt3_90_comp], {strokeColor:'black', dash: 1, strokeWidth:2, fixed:true});

var AB_60_comp = brd1.create('segment', [Pt1_60_comp, Pt2_60_comp], {strokeColor:'black', strokeWidth:1, fixed:true});
var AC_60_comp = brd1.create('segment', [Pt1_60_comp, Pt3_60_comp], {strokeColor:'black', strokeWidth:1, fixed:true});

var AB_30_comp = brd1.create('segment', [Pt1_30_comp, Pt2_30_comp], {strokeColor:'black', strokeWidth:1, fixed:true});

//Angles complementary
var ang90_comp = brd1.create('angle', [Pt2_90_comp, Pt1_90_comp, Pt3_90_comp],{name:'90^o',visible: true,  strokeColor:'green', fillColor:'green', radius:1.5, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, color:'green', cssStyle:'fontFamily:Oswald;'}});

//var ang90_compTxt = brd1.create('angle', [Pt2_90_comp, Pt1_90_comp, Pt3_90_comp],{name: function(){ return (180*ang90_comp.Value()/Math.PI).toFixed(0)  + '°'}, radius:1, strokeColor:'green', fillColor:'green', label: {color: 'green', fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var ang60_comp = brd1.create('angle', [Pt2_60_comp, Pt1_60_comp, Pt3_60_comp],{name:()=> '&beta;='+Math.round(JXG.Math.Geometry.rad(Pt2_60_comp, Pt1_60_comp, Pt3_60_comp)*180/Math.PI)+'^o', visible:true, radius:2, strokeColor:'red', fillColor:'red', label: {fontSize:function(){return 18*brd1.canvasHeight/800},color:'red',cssStyle:'fontFamily:Oswald;'}});

var ang30_comp = brd1.create('angle', [Pt2_30_comp, Pt1_30_comp, Pt2_60_comp],{name:()=> '&alpha;='+ Math.round(JXG.Math.Geometry.rad(Pt2_30_comp, Pt1_30_comp, Pt2_60_comp)*180/Math.PI)+'^o', visible: true, radius:2, strokeColor:'blue', fillColor:'blue', label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});


//SUPPLEMENTARY

//Variables supplementary
var Pt1_180_sup = brd1.create('point', [8,5],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var Pt2_180_sup = brd1.create('point', [2, 5],{visible: false, name:' ', size:2, strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var Pt3_180_sup = brd1.create('point', [14, 5],{visible: false, name:' ', size:2,  strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});


var Pt1_135_sup = brd1.create('point', [8,7],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var Pt3_135_sup = brd1.create('point', [2, 7],{visible: false, name:' ', size:2,  strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var Pt2_45_sup_fixed = brd1.create('point', [14, 7],{visible: false, name:' ', size:2, strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var circle_sup = brd1.create('arc', [Pt1_135_sup, Pt2_45_sup_fixed, Pt3_135_sup], {visible:false, dash:1, strokeWidth:1, strokeColor:'grey'});
var Pt2_135_sup = brd1.create('glider', [13, 10 , circle_sup ],{visible: false, name:'Drag Me!', size:2, strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var Pt1_45_sup = brd1.create('point', [function(){return Pt1_135_sup.X()},function(){return Pt1_135_sup.Y()}],{visible: false, name:'A', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:true, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var Pt2_45_sup = brd1.create('point', [14, 7],{visible: false, name:'B', size:2, strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

//Sides of the triangle supplementary
var AB_180_sup = brd1.create('segment', [Pt1_180_sup, Pt2_180_sup], {visible: false,strokeColor:'black', dash: 1 , strokeWidth:2, fixed:true});
var AC_180_sup = brd1.create('segment', [Pt1_180_sup, Pt3_180_sup], {visible: false,strokeColor:'black', dash: 1, strokeWidth:2, fixed:true});

var AB_135_sup = brd1.create('segment', [Pt1_135_sup, Pt2_135_sup], {visible: false,strokeColor:'black', strokeWidth:1, fixed:true});
var AC_135_sup = brd1.create('segment', [Pt1_135_sup, Pt3_135_sup], {visible: false,strokeColor:'black', strokeWidth:1, fixed:true});

var AB_45_sup = brd1.create('segment', [Pt1_45_sup, Pt2_45_sup], {visible: false,strokeColor:'black', strokeWidth:1, fixed:true});

//Angles supplementary
var ang180_sup = brd1.create('angle', [Pt3_180_sup, Pt1_180_sup, Pt2_180_sup],{name:'180^o', visible: false,  strokeColor:'green', fillColor:'green', label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
//var ang180_supTxt = brd1.create('angle', [Pt3_180_sup, Pt1_180_sup, Pt2_180_sup],{visible: false,name: function(){ return (180*ang180_sup.Value()/Math.PI).toFixed(0)  + '°'}, radius:1, strokeColor:'green', fillColor:'green', label: {offset: [-15, -15], color: 'green', fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var ang135_sup = brd1.create('angle', [Pt2_135_sup, Pt1_135_sup, Pt3_135_sup],{visible:false, name:()=> '&gamma;='+ Math.round(JXG.Math.Geometry.rad(Pt2_135_sup, Pt1_135_sup, Pt3_135_sup)*180/Math.PI)+'^o',  radius:1, strokeColor:'red', fillColor:'red', label: {color:'red',fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
//var ang135_supTxt = brd1.create('angle', [Pt2_135_sup, Pt1_135_sup, Pt3_135_sup],{visible: false,name: function(){ return (180*ang135_sup.Value()/Math.PI).toFixed(0)  + '°'}, radius:1, strokeColor:'red', fillColor:'red', label: {color: 'red', fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var ang45_sup = brd1.create('angle', [Pt2_45_sup, Pt1_45_sup, Pt2_135_sup],{visible:false, name:()=> '&delta;='+ Math.round(JXG.Math.Geometry.rad(Pt2_45_sup, Pt1_45_sup, Pt2_135_sup)*180/Math.PI)+'^o',  radius:1, strokeColor:'blue', fillColor:'blue', label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
//var ang45_supTxt = brd1.create('angle', [Pt2_45_sup, Pt1_45_sup, Pt2_135_sup],{visible: false,name: function(){ return (180*ang45_sup.Value()/Math.PI).toFixed(0)  + '°'}, radius:1, strokeColor:'blue', fillColor:'blue', label: {offset: [10, -15], color: 'blue', fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});


//THIRD STEP

var point1_90_comp = brd1.create('point', [2,5],{visible: false, fixed: true, name:'', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var point2_90_comp = brd1.create('point', [6, 5],{visible: false, fixed: true, name:' ', size:2, strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var point3_90_comp = brd1.create('point', [2, 9],{visible: false, fixed: true, name:' ', size:2,  strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var sideAB_90_comp = brd1.create('segment', [point1_90_comp, point2_90_comp], {visible: false, strokeColor:'black', dash: 1 , strokeWidth:2, fixed:true});
var sideAC_90_comp = brd1.create('segment', [point1_90_comp, point3_90_comp], {visible: false, strokeColor:'black', dash: 1, strokeWidth:2, fixed:true});

var point1_180_sup = brd1.create('point', [15,5],{visible: false, fixed: true, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var point2_180_sup = brd1.create('point', [19, 5],{visible: false, fixed: true, name:' ', size:2, strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var point3_180_sup = brd1.create('point', [11, 5],{visible: false, fixed: true, name:' ', size:2,  strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var sideAB_180_sup = brd1.create('segment', [point1_180_sup, point2_180_sup], {visible: false, strokeColor:'black', dash: 1 , strokeWidth:2, fixed:true});
var sideAC_180_sup = brd1.create('segment', [point1_180_sup, point3_180_sup], {visible: false, strokeColor:'black', dash: 1, strokeWidth:2, fixed:true});


//SHUFFLE

var shuffle = brd1.create('image', ['/assets/shuffle.svg', [12, 1.2], [1.5 , 1.5]], {visible: false, fixed: true});
//REDO AND UNDO
var redo = placeRight(brd1);
//brd1.create('image', ['/assets/redo.svg', [10, 1.2], [1.5 , 1.5]], {visible: true, fixed: true});
var undo = placeLeft(brd1);
//brd1.create('image', ['/assets/undo.svg', [8, 1.2], [1.5 , 1.5]], {visible: true, fixed: true});
var rnd = 60;
var rnd2 = 45;
shuffle.on('down',function(){
    score1 = 0;
    score2 = 0;

    p_1_blue.moveTo([15, 12], 10);
    p_1_red.moveTo([2, 12], 10);
    p_1_orange.moveTo([10, 12], 10);
    p_1_green.moveTo([7, 12], 10);

    rnd = Math.round(Math.random()*120);
    rnd2 = Math.round(Math.random()*80);
	checkmark1.setAttribute({visible:false});
	checkmark2.setAttribute({visible:false});

});

//Little angle 1

var p_1_blue = brd1.create('point', [15,12],{visible: false, name:'Drag me!', attractors: [point1_90_comp, point1_180_sup], attractorDistance: 1, size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {anchorX:'middle', offset:[-5, -10],fontSize:function(){return 12*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var p_2_blue = brd1.create('point', [function(){return p_1_blue.X() + 3},  function(){return p_1_blue.Y()}], {visible: false, name:' ', size:2, strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var p_3_blue = brd1.create('point', [function(){return p_1_blue.X() + 3*Math.cos(rnd.valueOf()*Math.PI/180)},  function(){return p_1_blue.Y() + 3*Math.sin(rnd.valueOf()*Math.PI/180) }], {visible: false, name:' ', size:2,  strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var AB_little_1 = brd1.create('segment', [p_1_blue, p_2_blue], {visible: false,strokeColor:'black', dash: 0 , strokeWidth:1, fixed:true});
var AC_little_1 = brd1.create('segment', [p_1_blue, p_3_blue], {visible: false,strokeColor:'black', dash: 0 , strokeWidth:1, fixed:true});

var littleAngle_1txt = brd1.create('angle', [p_2_blue, p_1_blue, p_3_blue],{name:()=>(JXG.Math.Geometry.rad(p_2_blue, p_1_blue, p_3_blue)*180/Math.PI).toFixed(0) +'°' , visible: false,  strokeColor:'blue', fillColor:'blue', label: {color: 'blue', offset: [0 , -10],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

//Little angle 2

var p_1_red = brd1.create('point', [2,12],{anchorX:'middle', visible: false, name:'Drag me!', attractors: [point1_90_comp, point1_180_sup], attractorDistance: 1, size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {offset:[-5, -10], fontSize:function(){return 12*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var p_2_red = brd1.create('point', [function(){return p_1_red.X() },  function(){return p_1_red.Y()+ 3}], {visible: false, name:' ', size:2, strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var p_3_red = brd1.create('point', [function(){return p_1_red.X() + 3*Math.sin(rnd2.valueOf()*Math.PI/180)},  function(){return p_1_red.Y() + 3*Math.cos(rnd2.valueOf()*Math.PI/180) }], {visible: false, name:' ', size:2,  strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var AB_little_2 = brd1.create('segment', [p_1_red, p_2_red], {visible: false,strokeColor:'black', dash: 0 , strokeWidth:1, fixed:true});
var AC_little_2 = brd1.create('segment', [p_1_red, p_3_red], {visible: false,strokeColor:'black', dash: 0 , strokeWidth:1, fixed:true});

var littleAngle_2txt = brd1.create('angle', [p_3_red, p_1_red, p_2_red],{name:()=>(JXG.Math.Geometry.rad(p_3_red, p_1_red, p_2_red)*180/Math.PI).toFixed(0) +'°' , visible: false,  strokeColor:'red', fillColor:'red', label: {color: 'red' , offset: [-5 , 0], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

//Little angle 3

var p_1_green = brd1.create('point', [7,12],{visible: false, name:'Drag me!', attractors: [point1_90_comp, point1_180_sup], attractorDistance: 1, size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {anchorX:'middle', offset:[-5, -10], fontSize:function(){return 12*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var p_2_green = brd1.create('point', [function(){return p_1_green.X() -3 },  function(){return p_1_green.Y()}], {visible: false, name:' ', size:2, strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var p_3_green = brd1.create('point', [function(){return p_1_green.X() + 3*Math.sin((90-rnd.valueOf())*Math.PI/180)},  function(){return p_1_green.Y() + 3*Math.cos((90-rnd.valueOf())*Math.PI/180) }], {visible: false, name:' ', size:2,  strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var AB_little_3 = brd1.create('segment', [p_1_green, p_2_green], {visible: false,strokeColor:'black', dash: 0 , strokeWidth:1, fixed:true});
var AC_little_3 = brd1.create('segment', [p_1_green, p_3_green], {visible: false,strokeColor:'black', dash: 0 , strokeWidth:1, fixed:true});

var littleAngle_3txt = brd1.create('angle', [p_3_green, p_1_green, p_2_green],{name:()=>(JXG.Math.Geometry.rad(p_3_green, p_1_green, p_2_green)*180/Math.PI).toFixed(0) +'°' , visible: false,  strokeColor:'green', fillColor:'green', label: {color: 'green' ,offset: [-5 , -10],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

//Little angle 4

var p_1_orange = brd1.create('point', [10,12],{visible: false, name:'Drag me!', attractors: [point1_90_comp, point1_180_sup], attractorDistance: 1, size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {anchorX:'middle',offset:[-5, -10], fontSize:function(){return 12*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var p_2_orange = brd1.create('point', [function(){return p_1_orange.X() + 3},  function(){return p_1_orange.Y()}], {visible: false, name:' ', size:2, strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var p_3_orange = brd1.create('point', [function(){return p_1_orange.X() + 3*Math.cos((90-rnd2.valueOf())*Math.PI/180)},  function(){return p_1_orange.Y() + 3*Math.sin((90-rnd2.valueOf())*Math.PI/180) }], {visible: false, name:' ', size:2,  strokeColor:'black', snapToGrid:false,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var AB_little_4 = brd1.create('segment', [p_1_orange, p_2_orange], {visible: false,strokeColor:'black', dash: 0 , strokeWidth:1, fixed:true});
var AC_little_4 = brd1.create('segment', [p_1_orange, p_3_orange], {visible: false,strokeColor:'black', dash: 0 , strokeWidth:1, fixed:true});

var littleAngle_4txt = brd1.create('angle', [p_2_orange, p_1_orange, p_3_orange],{name:()=>(JXG.Math.Geometry.rad(p_2_orange, p_1_orange, p_3_orange)*180/Math.PI).toFixed(0) +'°' ,  visible: false,  strokeColor:'orange', fillColor:'orange', label: {color: 'orange' ,offset: [0 , -10], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var i =0;
var maxSteps = 2;
var checkmark1 = brd1.create('image', ['/assets/check.svg', [1, 3.], [1. , 1.]], {visible: false, fixed: true});
var checkmark2 = brd1.create('image', ['/assets/check.svg', [12, 3.], [1. , 1.]], {visible: false, fixed: true});


//setInterval(checkPosition, 100);
var score1 =0;
var score2 =0;
var checkPosition = function(){
	score1 =0;
	score2 =0;
	checkmark1.setAttribute({visible:false});
	checkmark2.setAttribute({visible:false});
    if(p_1_orange.X() == point1_90_comp.X() && p_1_orange.Y() == point1_90_comp.Y() &&
    p_1_red.X() == point1_90_comp.X() && p_1_red.Y() == point1_90_comp.Y())
    {
     score1 = 0.5;
	 checkmark1.setAttribute({visible:true});
    }
    if(p_1_green.X() == point1_180_sup.X() && p_1_green.Y() == point1_180_sup.Y() &&
    p_1_blue.X() == point1_180_sup.X() && p_1_blue.Y() == point1_180_sup.Y() )
    {
    score2 = 0.5;
	checkmark2.setAttribute({visible:true});
    }
    else{
        return;
    }
}
p_1_red.on('up', function(){checkPosition()});
p_1_blue.on('up',function(){checkPosition()});
p_1_green.on('up',function(){checkPosition()});
p_1_orange.on('up',function(){checkPosition()});
var scoretxt1 = brd1.create('text', [9.5, 16.5,  function(){return 'Score = '+ (score1+score2).toFixed(1)}],{ anchorX:'middle', anchorY:'middle', visible: false, color: 'black', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)},fixed:true});

function updateStepTexts(){
    eraseText();

    if (i<=0){
		circle_comp.setAttribute({visible:true});
        AB_90_comp.setAttribute({visible: true});
        AC_90_comp.setAttribute({visible: true});
        AB_60_comp.setAttribute({visible: true});
        AC_60_comp.setAttribute({visible: true});
        AB_30_comp.setAttribute({visible: true});
        ang90_comp.setAttribute({visible: true});
        ang60_comp.setAttribute({visible: true});
        ang30_comp.setAttribute({visible:true});
        title.setAttribute({visible: true});
        subtitle.setAttribute({visible: true});
        Pt2_60_comp.setAttribute({visible: true});
    }
    if(i==1){
        circle_sup.setAttribute({visible:true});
        title2.setAttribute({visible: true});
        subtitle2.setAttribute({visible: true});
        AB_180_sup.setAttribute({visible: true});
        AC_180_sup.setAttribute({visible: true});
        AB_135_sup.setAttribute({visible: true});
        AC_135_sup.setAttribute({visible: true});
        AB_45_sup.setAttribute({visible: true});
        ang180_sup.setAttribute({visible: true});
        ang135_sup.setAttribute({visible: true});
        ang45_sup.setAttribute({visible: true});
        Pt2_135_sup.setAttribute({visible: true});
    }

    if(i==2){

        subtitle3.setAttribute({visible: true});
        text1.setAttribute({visible: true});
        text2.setAttribute({visible: true});

        point1_90_comp.setAttribute({visible: true});
        point1_180_sup.setAttribute({visible: true});

        sideAB_90_comp.setAttribute({visible: true});
        sideAC_90_comp.setAttribute({visible: true});

        sideAB_180_sup.setAttribute({visible: true});
        sideAC_180_sup.setAttribute({visible: true});

        p_1_blue.setAttribute({visible: true});
        AB_little_1.setAttribute({visible: true});
        AC_little_1.setAttribute({visible: true});
        littleAngle_1txt.setAttribute({visible: true});

        p_1_red.setAttribute({visible: true});
        AB_little_2.setAttribute({visible: true});
        AC_little_2.setAttribute({visible: true});
        littleAngle_2txt.setAttribute({visible: true});

        p_1_green.setAttribute({visible: true});
        AB_little_3.setAttribute({visible: true});
        AC_little_3.setAttribute({visible: true});
        littleAngle_3txt.setAttribute({visible: true});

        p_1_orange.setAttribute({visible: true});
        AB_little_4.setAttribute({visible: true});
        AC_little_4.setAttribute({visible: true});
        littleAngle_4txt.setAttribute({visible: true});

       scoretxt1.setAttribute({visible: true});
       shuffle.setAttribute({visible: true});

    }

}

function eraseText(){
	circle_sup.setAttribute({visible:false});
    circle_comp.setAttribute({visible:false});
    p_1_blue.moveTo([15, 12], 10);
    p_1_red.moveTo([2, 12], 10);
    p_1_orange.moveTo([10, 12], 10);
    p_1_green.moveTo([7, 12], 10);

    Pt2_60_comp.setAttribute({visible: false});
	 checkmark1.setAttribute({visible:false});
	 	 checkmark2.setAttribute({visible:false});
    AB_90_comp.setAttribute({visible: false});
    AC_90_comp.setAttribute({visible: false});
    AB_60_comp.setAttribute({visible: false});
    AC_60_comp.setAttribute({visible: false});
    AB_30_comp.setAttribute({visible: false});

    ang90_comp.setAttribute({visible: false});
    ang60_comp.setAttribute({visible: false});
    ang30_comp.setAttribute({visible: false});

    title.setAttribute({visible: false});
    subtitle.setAttribute({visible: false});

    title2.setAttribute({visible: false});
    subtitle2.setAttribute({visible: false});

    Pt2_135_sup.setAttribute({visible: false});

    AB_180_sup.setAttribute({visible: false});
    AC_180_sup.setAttribute({visible: false});
    AB_135_sup.setAttribute({visible: false});
    AC_135_sup.setAttribute({visible: false});
    AB_45_sup.setAttribute({visible: false});

    ang180_sup.setAttribute({visible: false});
    ang135_sup.setAttribute({visible: false});
    ang45_sup.setAttribute({visible: false});

    subtitle3.setAttribute({visible: false});
    text1.setAttribute({visible: false});
    text2.setAttribute({visible: false});

    point1_90_comp.setAttribute({visible: false});
    point1_180_sup.setAttribute({visible: false});

    sideAB_90_comp.setAttribute({visible: false});
    sideAC_90_comp.setAttribute({visible: false});

    sideAB_180_sup.setAttribute({visible: false});
    sideAC_180_sup.setAttribute({visible: false});

    p_1_blue.setAttribute({visible: false});
    AB_little_1.setAttribute({visible: false});
    AC_little_1.setAttribute({visible: false});
    littleAngle_1txt.setAttribute({visible: false});

    p_1_red.setAttribute({visible: false});
    AB_little_2.setAttribute({visible: false});
    AC_little_2.setAttribute({visible: false});
    littleAngle_2txt.setAttribute({visible: false});

    p_1_green.setAttribute({visible: false});
    AB_little_3.setAttribute({visible: false});
    AC_little_3.setAttribute({visible: false});
    littleAngle_3txt.setAttribute({visible: false});

    p_1_orange.setAttribute({visible: false});
    AB_little_4.setAttribute({visible: false});
    AC_little_4.setAttribute({visible: false});
    littleAngle_4txt.setAttribute({visible: false});

    scoretxt1.setAttribute({visible: false});

    shuffle.setAttribute({visible: false});
}

redo.on('down',function(){
    i = i+1;
    if (i > maxSteps){
        i = maxSteps;
    }
    updateStepTexts();
});

undo.on('down',function(){
    i=i-1;
    if (i < 0){
        i = 0;
    }
    eraseText();
    updateStepTexts();
});

brd1.unsuspendUpdate();
},
}
export default Boxes;
