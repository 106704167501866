<template>
  <div>
    <h3 ref="ca">
      Complementary Angles
    </h3>
    <p>
      Two angles are said to be the complement of each other when their sum is equal to 90 degrees.
      For example, if angle &alpha; and &beta; are complementary angles, then they are related as follows:
      $$\alpha + \beta  = 90^o$$
      Geometrically, this means that when such two angles are put adjacent to each other, they will form a right-angled corner.
    </p>
    <br>
    <v-layout justify-center>
      <v-img src="/assets/complimentary_angles.png"
             max-height="450px"
             max-width="450px"
             contain
      />
    </v-layout>
    <h5 style="text-align:center">
      Figure: Example of complimentary angles.
    </h5>
    <h3 ref="sa">
      Supplementary Angles
    </h3>
    <p>
      Two angles are said to be the supplement of each other when their sum is equal to 180 degrees.
      For example, if angle &alpha; and &beta; are supplementary angles, then they are related as follows:
      $$\gamma + \delta  = 180^o$$
      Geometrically, this means that when such two angles are put adjacent to each other, they form a straight line.
    </p>
    <v-layout justify-center>
      <v-img src="/assets/supplementary_angles.png"
             max-height="450px"
             max-width="450px"
             contain
      />
    </v-layout>
    <h5 style="text-align:center">
      Figure: Example of supplementary angles.
    </h5>
    <h3 ref="ra">
      Special Remarks
    </h3>
    <p>  Based on the definitions of complementary and supplementary angles, following conclusions can be drawn:</p>
    <ul style="list-style-type: square;">
      <li>Two acute angles can complement to each other, but they could never be supplementary. </li>
      <li> Two obtuse angles can neither be supplementary nor complementary. </li>
      <li> Two right angles can only be supplementary. </li>
      <li> One acute angle and one obtuse angle can be the supplement of each other.</li>
    </ul>
    <br>
    <h3 ref="playgraph">
      MagicGraph &vert; Complementary and Supplementary Angles
    </h3>
    <p>
      This MagicGraph offers a geometric approach to understanding complementary and supplementary angles.
    </p>
    <!--    <h5> To Get Started: </h5>
    <p>
    You are given a triangle formed by points O, A and B.
    The vertices O, A, and B of the triangle can be dragged and moved around to change
    the type (i.e., acute, obtuse or right) and size of the triangle.
    You will be able to see that the three interior angles &alpha;, &beta; and &gamma; change as the vertices are dragged around.
    </p>
<h5> To Explore:</h5>
    <p>
 Drag points O, A and B of the triangle to change the type (i.e., acute, obtuse or right) and size of the triangle OAB.
 Then, tap on the two scissors to cut out the angles &alpha; and &beta;.
 No matter what type or size of the triangle you choose, the three interior
 angles &alpha;, &beta; and &gamma; always form a half circle when stacked adjacent to each other.
 </p> -->
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Angles',
  created: function () {
      this.$store.commit('navigation/resetState');
    // Store mutations
    let title = 'Complementary & Supplementary Angles';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'complementary Angle', img:'/assets/number-1.svg', action: () => this.goto('ca')},
      {title: 'Supplementary Angle', img:'/assets/number-2.svg', action: () => this.goto('sa')},
      {title: 'Special Remarks', img:'/assets/number-3.svg', action: () => this.goto('ra')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('playgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Angles',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
